<template>
  <router-view />
</template>

<script>
import NectiosLogo from '@/assets/images/placeholders/light/nectios-favicon.png';

export default {
  name: 'AppStoreRoot',
  computed: {
    faviconURL() {
      return NectiosLogo;
    },
  },
};
</script>
